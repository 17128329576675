<template>
  <v-card flat>
    <v-sheet v-if="$vuetify.breakpoint.lgAndUp && !isDialog">
      <v-row no-gutters align="center">
        <v-select
          v-model="idSchoolYear"
          :label="$t('school_year.header.school_year')"
          :items="schoolYearList"
          :loading="loadingSchoolYear"
          item-value="id"
          class="select-200 d-inline-block mr-2"
          outlined
          dense
          hide-details
          @change="changeFilter('school_year')"
        >
          <template #item="{ item }">
            <span class="caption">
              {{ appendSemester(item) }}
            </span>
            <v-tooltip right>
              <template #activator="{ on }">
                <v-icon
                  v-on="on"
                  :color="schoolYearInfo(item.status).color"
                  small
                  class="ml-1"
                >
                  {{ schoolYearInfo(item.status).name }}
                </v-icon>
              </template>
              <span>{{ schoolYearInfo(item.status).info }}</span>
            </v-tooltip>
          </template>
          <template v-slot:selection="{ item }">
            <span class="mr-2 caption">
              {{ appendSemester(item) }}
            </span>
          </template>
        </v-select>
        <v-select
          v-model="idGrade"
          :label="$t('app.grade')"
          :items="gradeList"
          :loading="loadingGrade"
          item-value="id"
          item-text="grade"
          class="select-120 d-inline-block mr-2"
          outlined
          dense
          hide-details
          @change="changeFilter('grade')"
        >
          <template v-slot:selection="{ item }">
            <span class="mr-2 body-1">{{ item.grade }}</span>
          </template>
        </v-select>
        <v-select
          v-model="idClass"
          :disabled="idGrade === 0"
          :label="$t('app.class')"
          :items="classList"
          :loading="loadingClass"
          :multiple="multipleClass"
          clearable
          item-value="id"
          item-text="name"
          class="select-150 d-inline-block mr-2"
          outlined
          dense
          hide-details
          @change="changeFilter('class')"
        >
          <template v-slot:selection="{ item }">
            <span class="mr-2">{{ item.name }}</span>
          </template>
        </v-select>
        <v-autocomplete
          v-if="isAdmin"
          v-model="idSubjects"
          :disabled="idGrade === 0"
          :label="$t('app.subjects')"
          :items="listSubjects"
          :loading="loadingSubjects"
          item-value="lesson"
          item-text="lesson_name"
          class="select-175 d-inline-block mr-2"
          outlined
          dense
          hide-details
          clearable
          @change="changeFilter('subjects')"
        />
        <v-autocomplete
          v-if="isAdmin"
          v-model="idTeacher"
          :label="$t('app.teacher')"
          :items="listTeacher"
          :loading="loadingTeacher"
          item-value="id"
          item-text="name"
          class="select-175 d-inline-block mr-2"
          outlined
          dense
          hide-details
          clearable
          @change="changeFilter('teacher')"
        />
        <v-select
          v-model="modelType"
          :label="$t('app.type')"
          :items="types"
          item-value="id"
          item-text="name"
          class="select-120 d-inline-block"
          outlined
          dense
          hide-details
          @change="changeFilter('type')"
        />
      </v-row>
    </v-sheet>

    <v-sheet v-else>
      <v-btn color="primary" @click="bottomMenu = !bottomMenu" depressed>
        <v-icon>mdi-filter-outline</v-icon>
      </v-btn>
    </v-sheet>

    <v-bottom-sheet v-model="bottomMenu">
      <v-sheet class="pa-6">
        <v-row>
          <v-col cols="12" sm="4">
            <v-select
              v-model="idSchoolYear"
              :label="$t('school_year.header.school_year')"
              :items="schoolYearList"
              :loading="loadingSchoolYear"
              item-value="id"
              class="mb-3"
              outlined
              dense
              hide-details
              @change="changeFilter('school_year')"
            >
              <template #item="{ item }">
                <span class="caption">
                  {{ appendSemester(item) }}
                </span>
                <v-tooltip right>
                  <template #activator="{ on }">
                    <v-icon
                      v-on="on"
                      :color="schoolYearInfo(item.status).color"
                      small
                      class="ml-1"
                    >
                      {{ schoolYearInfo(item.status).name }}
                    </v-icon>
                  </template>
                  <span>{{ schoolYearInfo(item.status).info }}</span>
                </v-tooltip>
              </template>
              <template v-slot:selection="{ item }">
                <span class="caption">
                  {{ appendSemester(item) }}
                </span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              v-model="idGrade"
              :label="$t('app.grade')"
              :items="gradeList"
              :loading="loadingGrade"
              item-value="id"
              item-text="grade"
              outlined
              class="mb-3"
              dense
              hide-details
              @change="changeFilter('grade')"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              v-model="idClass"
              :disabled="idGrade === 0"
              :label="$t('app.class')"
              :items="classList"
              :loading="loadingClass"
              :multiple="multipleClass"
              clearable
              item-value="id"
              item-text="name"
              class="mb-3"
              outlined
              dense
              hide-details
              @change="changeFilter('class')"
            />
          </v-col>
          <v-col v-if="isAdmin" cols="12" sm="4">
            <v-autocomplete
              v-model="idSubjects"
              :disabled="idGrade === 0"
              :label="$t('app.subjects')"
              :items="listSubjects"
              :loading="loadingSubjects"
              item-value="lesson"
              item-text="lesson_name"
              clearable
              class="mb-3"
              outlined
              dense
              hide-details
              @change="changeFilter('subjects')"
            />
          </v-col>
          <v-col v-if="isAdmin" cols="12" sm="4">
            <v-autocomplete
              v-model="idTeacher"
              :label="$t('app.teacher')"
              :items="listTeacher"
              :loading="loadingTeacher"
              clearable
              item-value="id"
              item-text="name"
              class="mb-3"
              outlined
              dense
              hide-details
              @change="changeFilter('teacher')"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              v-model="modelType"
              :label="$t('app.type')"
              :items="types"
              item-value="id"
              item-text="name"
              outlined
              dense
              hide-details
              @change="changeFilter('type')"
            />
          </v-col>
        </v-row>
      </v-sheet>
    </v-bottom-sheet>
  </v-card>
</template>

<script>
import {
  get_school_year_list,
  get_grade_list,
  get_class_list
} from "@/api/admin/schoolClass";

import { teachers, lessons } from "@/api/admin/academic/silabus";

export default {
  props: {
    isDialog: Boolean,
    resetFilter: Boolean,
    multipleClass: Boolean
  },
  watch: {
    resetFilter(newVal) {
      if (newVal) {
        this.idTeacher = 0;
        this.idSubjects = 0;
        this.idGrade = 0;
        this.idClass = 0;
      }
    }
  },
  data() {
    return {
      modelType: "OTHER",
      types: [
        { id: "OTHER", name: this.$i18n.t("app.others") },
        { id: "SILABUS", name: "Silabus" },
        { id: "RPP", name: "RPP" }
      ],
      bottomMenu: false,
      loadingSchoolYear: false,
      loadingGrade: false,
      loadingClass: false,
      loadingSubjects: false,
      loadingTeacher: false,
      idTeacher: 0,
      listTeacher: [],
      idSubjects: 0,
      listSubjects: [],
      idSchoolYear: 0,
      schoolYearList: [],
      idGrade: 0,
      gradeList: [],
      idClass: 0,
      classList: [],
      isAdmin: this.$store.getters.g_role_type === "ADMIN",
      typeList:
        this.$store.getters.g_role_type === "TEACHER" ? "HOMEROOM_TEACHER" : ""
    };
  },
  mounted() {
    this.$nextTick(async () => {
      await this.getSchoolYear();
      await this.getGradeList();
      if (this.isAdmin) await this.getTeachers();
    });
  },
  methods: {
    async getClassList() {
      if (this.idGrade != 0) {
        this.loadingClass = true;
        const body = {
          grade: [this.idGrade],
          school_year: [this.idSchoolYear],
          type: this.typeList
        };
        try {
          const res = await get_class_list(body);
          if (res.data.code) this.classList = res.data.data;
        } catch (error) {
          console.error("getClassList()\n", error);
          this.loadingClass = false;
        }
        this.loadingClass = false;
      }
    },
    async getLessons(grade, classroom) {
      this.loadingSubjects = true;
      try {
        const res = await lessons({
          grade: grade ?? 0,
          classroom: classroom ?? 0
        });
        if (res.data.code) this.listSubjects = res.data.data;
      } catch (error) {
        console.error("getLessons()\n", error);
        this.loadingSubjects = false;
      }
      this.loadingSubjects = false;
    },
    async getGradeList() {
      this.loadingGrade = true;
      try {
        const res = await get_grade_list({ type: this.typeList });
        if (res.data.code)
          this.gradeList = [{ id: 0, grade: this.$i18n.t("app.all") }].concat(
            res.data.data
          );
      } catch (error) {
        console.error("getGradeList()\n", error);
        this.loadingGrade = false;
      }
      this.loadingGrade = false;
    },
    async getTeachers() {
      this.loadingTeacher = true;
      try {
        const res = await teachers();
        if (res.data.code) this.listTeacher = res.data.data;
      } catch (error) {
        console.error("getTeacher()\n", error);
        this.loadingTeacher = false;
      }
      this.loadingTeacher = false;
    },
    async getSchoolYear() {
      this.loadingSchoolYear = true;
      try {
        const res = await get_school_year_list(false);
        if (res.data.code) {
          const data = res.data.data;
          this.schoolYearList = data;
          if (data.length > 0) {
            const getActiveYear = data.find(r => r.status == 1);
            if (getActiveYear) {
              this.idSchoolYear = getActiveYear.id;
              this.changeFilter("school_year");
            }
          }
        }
      } catch (error) {
        console.error("getSchoolYear()\n", error);
        this.loadingSchoolYear = false;
      }
      this.loadingSchoolYear = false;
    },
    changeFilter(param) {
      let selected = 0;
      switch (param) {
        case "school_year":
          selected = this.idSchoolYear;
          this.getClassList();
          break;
        case "grade":
          selected = this.idGrade ?? 0;
          this.getClassList();
          this.getLessons(selected);
          break;
        case "class":
          selected = this.idClass ?? 0;
          this.getLessons(this.idGrade, selected);
          break;
        case "subjects":
          selected = this.idSubjects ?? 0;
          break;
        case "teacher":
          selected = this.idTeacher ?? 0;
          break;
        case "type":
          selected = this.modelType;
          break;
      }
      this.$emit("handler", {
        type: param,
        selected: selected
      });
    },
    appendSemester(item) {
      return `${item.start_year} / ${item.end_year}, semester ${item.semester}`;
    },
    schoolYearInfo(status) {
      let icon = { name: "", color: "", info: "" };
      const active = {
        name: "mdi-check-circle-outline",
        color: "success",
        info: this.$i18n.t("school_year.current_school_year")
      };
      const willCome = {
        name: "mdi-timer-sand",
        color: "grey",
        info: this.$i18n.t("school_year.upcoming_school_year")
      };
      switch (status) {
        case 1:
          icon = active;
          break;
        case 2:
          icon = willCome;
          break;
      }
      return icon;
    }
  }
};
</script>
